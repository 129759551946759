export type LANGUAGES = 'en' | 'de' |'fr' |'nl';

export const SUPPORTED_LANGUAGES : Array<LANGUAGES> = [
  'en',
  'de',
  'fr',
  'nl',
];

export const LANGUAGE_DISPLAY_NAME : Record<LANGUAGES, string> = {
  de: 'Deutsch',
  en: 'English',
  fr: 'Français',
  nl: 'Nederlands'
}

export const META_LANGUAGES : Array<{ lang: string, default?: boolean, pathPrefix?: string }> = [
  {
    lang: 'en',
    default: true
  },
  {
    lang: 'nl',
    pathPrefix: 'nl'
  },
  {
    lang: 'de',
    pathPrefix: 'de'
  },
  {
    lang: 'fr',
    pathPrefix: 'fr'
  }
]

export const FALLBACK_LANGUAGE = 'en';

export const FALLBACK_REGION = 'us';

export const FALLBACK_TIMEZONE = 'Europe/London';

export const getUrlPathWithoutLanguage = ( path: string, search?: string ) => {
  
  for ( const prefix of SUPPORTED_LANGUAGES ) {
    if ( path.toLowerCase().startsWith( `/${prefix}` ) ) {
      if ( search ) {
        return `/${path.slice( prefix.length + 2 )}${search}`;
      }

      return `/${path.slice( prefix.length + 2 )}`;
    }
  }

  if ( search ) {
    return `${path}${search}`;
  }

  return path;
}
